import { IFooterCard, IImage } from '../../types/custom'

export interface IJobOfferInformation {
  uid: string
  title: string
  subtitle: string
  contractType: string
  type: string
  diploma?: string
  description: string
  city: string
  region: string
  date: string
  parent: {
    uid: string
    name: string
    parent?: {
      uid: string
      name: string
    }
  }
}

export interface IJobOfferTitle {
  value: string
}

export interface IJobOfferText {
  value: string
}

export interface IJobOfferImage extends IImage {}

export interface IJobOfferBanner extends IImage {}

export interface IJobOfferVideo {
  embed: string
  description: string
}

export interface IJobOfferSlice {
  type: JobOfferSliceType
  content: IJobOfferTitle | IJobOfferText | IJobOfferImage | IJobOfferBanner | IJobOfferVideo | IFooterCard[]
}

export enum JobOfferSliceType {
  TITLE = 'TITLE',
  TEXT = 'TEXT',
  IMAGE = 'IMAGE',
  BANNER = 'BANNER',
  VIDEO = 'VIDEO',
  FOOTER_CARDS = 'FOOTER_CARDS',
}
