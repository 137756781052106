/* eslint-disable no-irregular-whitespace */
import React from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'

import Layout from '../../layouts/default'
import Breadcrumb from '../../components/common/breadcrumb'
import { getTitle } from '../../utils/prismic'
import { linkGenerator, PrismicDocumentType } from '../../utils/link-generator'
import FooterCards from '../../components/common/slices/footer-cards'
import JobOfferForm from '../../components/forms/job-offer'
import { IFooterCard } from '../../types/custom'
import { TemplateJobOfferFormDataQuery } from '../../../gatsby-types'
import { transformSeo } from '../../utils/seo-transformer'

import { transformJobOfferNode } from './transformer'
import { JobOfferSliceType } from './types'

interface IProps {
  data: TemplateJobOfferFormDataQuery
  path?: string
}

export default function JobApplication({ data: { prismicJobOffer }, path }: IProps) {
  const jobOffer = transformJobOfferNode(prismicJobOffer)
  const seoContent =
    prismicJobOffer && prismicJobOffer.data && prismicJobOffer.data.seo && prismicJobOffer.data.seo[0]
      ? transformSeo(prismicJobOffer.data.seo[0])
      : undefined

  const { uid, contractType, parent, title, slices } = jobOffer

  const footerCardsData = slices.find((slice) => slice.type === JobOfferSliceType.FOOTER_CARDS)

  return (
    <Layout path={path} seoContent={seoContent} title={getTitle(jobOffer.title)}>
      <Container>
        <Breadcrumb
          links={[
            { label: parent.parent?.name || '', url: linkGenerator(PrismicDocumentType.HOME) },
            {
              label: parent.name,
              url: linkGenerator(PrismicDocumentType.PAGE, parent.uid),
            },
            { label: contractType, url: linkGenerator(PrismicDocumentType.JOB_OFFER, uid, parent.uid) },
            { label: title.toUpperCase(), url: linkGenerator(PrismicDocumentType.JOB_OFFER_FORM, uid, parent.uid) },
          ]}
        />
        <h2>Formulaire de candidature</h2>
        <h6>
          Vous postulez pour l’offre <span>{`« ${title} »`}</span>
        </h6>
        <JobOfferForm jobName={title} />
        {footerCardsData && <FooterCards content={footerCardsData.content as IFooterCard[]} />}
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query TemplateJobOfferFormData($uid: String!) {
    prismicJobOffer(uid: { eq: $uid }) {
      uid
      data {
        contracttype
        parentdocument {
          uid
          document {
            ... on PrismicCareer {
              data {
                name {
                  text
                }
                parentdocument {
                  uid
                  document {
                    ... on PrismicHome {
                      data {
                        name {
                          text
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        title {
          text
        }
        type {
          text
        }
        diploma {
          text
        }
        description {
          html
        }
        city {
          text
        }
        region {
          text
        }
        date(locale: "fr", formatString: "DD.MM.YYYY")
        body {
          ... on PrismicJobOfferBodyTitle {
            slice_type
            primary {
              offertitle {
                text
              }
            }
          }
          ... on PrismicJobOfferBodyText {
            slice_type
            primary {
              offertext {
                html
              }
            }
          }
          ... on PrismicJobOfferBodyImage {
            slice_type
            primary {
              image {
                alt
                url
              }
            }
          }
          ... on PrismicJobOfferBodyBanner {
            slice_type
            primary {
              image {
                alt
                url
              }
            }
          }
          ... on PrismicJobOfferBodyVideo {
            slice_type
            primary {
              offeryoutubeembed {
                text
              }
              offervideodescription {
                text
              }
            }
          }
          ... on PrismicJobOfferBodyFooterCards {
            slice_type
            items {
              footercardtitle {
                text
              }
              footercardsubtitle {
                text
              }
              footercardtext {
                text
              }
              footercardlinklabel {
                text
              }
              footercardlinkurl {
                url
              }
              footercardbackground {
                url
              }
            }
          }
        }
        seo {
          ... on PrismicJobOfferSeoMeta {
            id
            primary {
              metatitle {
                text
              }
              metadecription {
                text
              }
              metaogtype {
                text
              }
              metaogtitle {
                text
              }
              metaogdescription {
                text
              }
              metaogimage {
                url
              }
              metaogurl {
                text
              }
              metaogsitename {
                text
              }
              metatwittercard {
                text
              }
              metatwittertitle {
                text
              }
              metatwitterdescription {
                text
              }
              metatwitterimage {
                url
              }
              canonicallink {
                text
              }
            }
          }
        }
      }
    }
  }
`

const Container = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;

  & > h2 {
    color: ${({ theme }) => theme.palette.primary.main};
    font-family: 'Proxima Nova Extrabold', sans-serif;
    font-size: 52px;
    margin: 16px 0 24px 0;
    text-transform: uppercase;

    @media screen and (max-width: 480px) {
      font-size: 34px;
      margin: 24px 0;
    }
  }

  & > h6 {
    font-family: 'Proxima Nova Extrabold', sans-serif;
    font-size: 24px;
    margin-bottom: 32px;

    & > span {
      color: ${({ theme }) => theme.palette.primary.main};
    }
  }
`
