/* eslint-disable complexity */
/* eslint-disable @typescript-eslint/camelcase */
import { TemplateJobOfferDataQuery } from '../../../gatsby-types'

import { IJobOfferInformation, IJobOfferSlice, JobOfferSliceType } from './types'

export function transformAllJobOfferNode(
  nodes: TemplateJobOfferDataQuery['allPrismicJobOffer']['nodes']
): IJobOfferInformation[] {
  if (!nodes) throw new Error('Invalid job_offer nodes.')

  return nodes.map((node) => {
    if (!node) throw new Error('Invalid job_offer node.')

    const { uid, data } = node
    if (!uid || !data) throw new Error('Invalid job_offer node.')

    const { title, subtitle, contracttype, description, city, date, parentdocument } = data

    if (
      !title ||
      !title.text ||
      !contracttype ||
      !description ||
      !description.text ||
      !city ||
      !city.text ||
      !date ||
      !parentdocument ||
      !parentdocument.uid ||
      !parentdocument.document ||
      !parentdocument.document.data ||
      !parentdocument.document.data.name ||
      !parentdocument.document.data.name.text
    ) {
      throw new Error(`Invalid job_offer node : ${uid}.`)
    }

    return {
      uid,
      title: title.text,
      subtitle: subtitle?.text || '',
      contractType: contracttype,
      description: description.text,
      city: city.text,
      date,
      parent: {
        uid: parentdocument.uid,
        name: parentdocument.document.data.name.text,
      },
    }
  })
}

export function transformJobOfferNode(
  node: TemplateJobOfferDataQuery['prismicJobOffer']
): IJobOfferInformation & { slices: IJobOfferSlice[] } {
  if (!node) throw new Error('Invalid job_offer node.')

  const { uid, data } = node
  if (!uid || !data) throw new Error('Invalid job_offer nodes.')

  const { title, contracttype, type, diploma, description, city, region, date, parentdocument, body } = data

  if (
    !title ||
    !title.text ||
    !contracttype ||
    !type ||
    !type.text ||
    !description ||
    !description.html ||
    !city ||
    !city.text ||
    !region ||
    !region.text ||
    !date ||
    !parentdocument ||
    !parentdocument.uid ||
    !parentdocument.document ||
    !parentdocument.document.data ||
    !parentdocument.document.data.name ||
    !parentdocument.document.data.name.text ||
    !parentdocument.document.data.parentdocument ||
    !parentdocument.document.data.parentdocument.uid ||
    !parentdocument.document.data.parentdocument.document ||
    !parentdocument.document.data.parentdocument.document.data ||
    !parentdocument.document.data.parentdocument.document.data.name ||
    !parentdocument.document.data.parentdocument.document.data.name.text ||
    !body
  ) {
    throw new Error(`Invalid job_offer node : ${uid}`)
  }

  return {
    uid,
    title: title.text,
    subtitle: title.text || '',
    contractType: contracttype,
    type: type.text,
    diploma: diploma?.text || undefined,
    description: description.html,
    city: city.text,
    region: region.text,
    date,
    parent: {
      uid: parentdocument.uid,
      name: parentdocument.document.data.name.text,
      parent: {
        uid: parentdocument.document.data.parentdocument.uid,
        name: parentdocument.document.data.parentdocument.document.data.name.text,
      },
    },
    slices: body.map((slice) => {
      if (!slice) throw new Error(`Invalid job_offer node : ${uid}.`)

      // TODO: fix using discriminated unions from "gatsby-types-2.ts"
      const { slice_type, primary, items } = slice

      switch (slice_type) {
        case 'title': {
          if (!primary || !primary.offertitle || !primary.offertitle.text) {
            throw new Error(`Invalid job_offer title slice : ${uid}.`)
          }

          return { type: JobOfferSliceType.TITLE, content: { value: primary.offertitle.text } }
        }
        case 'text': {
          if (!primary || !primary.offertext || !primary.offertext.html) {
            throw new Error(`Invalid job_offer text slice : ${uid}.`)
          }

          return { type: JobOfferSliceType.TEXT, content: { value: primary.offertext.html } }
        }
        case 'image': {
          if (!primary || !primary.image || !primary.image.url) {
            throw new Error(`Invalid job_offer image slice : ${uid}.`)
          }

          return {
            type: JobOfferSliceType.IMAGE,
            content: { alt: primary.image.alt || '', url: primary.image.url },
          }
        }
        case 'banner': {
          if (!primary || !primary.image || !primary.image.url) {
            throw new Error(`Invalid job_offer banner slice : ${uid}.`)
          }

          return {
            type: JobOfferSliceType.BANNER,
            content: { alt: primary.image.alt || '', url: primary.image.url },
          }
        }
        case 'video': {
          if (!primary || !primary.offeryoutubeembed || !primary.offeryoutubeembed.text) {
            throw new Error(`Invalid job_offer video slice : ${uid}.`)
          }

          return {
            type: JobOfferSliceType.VIDEO,
            content: {
              embed: primary.offeryoutubeembed.text,
              description: primary.offervideodescription?.text || '',
            },
          }
        }
        case 'footer_cards': {
          if (!items) throw new Error(`Invalid job_offer footer_cards slice : ${uid}.`)

          return {
            type: JobOfferSliceType.FOOTER_CARDS,
            content: items.map((item) => {
              if (!item) throw new Error(`Invalid job_offer footer_cards slice : ${uid}.`)

              const {
                footercardtitle,
                footercardsubtitle,
                footercardtext,
                footercardlinklabel,
                footercardlinkurl,
                footercardbackground,
              } = item

              if (
                !footercardtitle ||
                !footercardtitle.text ||
                !footercardsubtitle ||
                !footercardsubtitle.text ||
                !footercardtext ||
                !footercardtext.text ||
                !footercardlinklabel ||
                !footercardlinklabel.text ||
                !footercardlinkurl ||
                !footercardlinkurl.url ||
                !footercardbackground ||
                !footercardbackground.url
              ) {
                throw new Error(`Invalid job_offer footer_cards slice : ${uid}.`)
              }

              return {
                title: footercardtitle.text,
                subtitle: footercardsubtitle.text,
                text: footercardtext.text,
                link: { label: footercardlinklabel.text, url: footercardlinkurl.url },
                background: { alt: '', url: footercardbackground.url },
              }
            }),
          }
        }
        default: {
          throw new Error(`Invalid job_offer node : ${uid}. ${slice_type} does not exist.`)
        }
      }
    }),
  }
}
